import { DocumentNode } from "graphql";
import httpClient from "./httpClient";

export const graphqlPublicQuery = async (
  query: string | DocumentNode,
  variables: Record<string, unknown>,
): Promise<unknown> => {
  return httpClient
    .post("/graphql_public", {
      query,
      variables,
    })
    .then(function (response) {
      return response.data;
    });
};

export const graphqlQuery = async (
  query: string,
  variables: Record<string, unknown>,
): Promise<unknown> => {
  return httpClient
    .post("/graphql", {
      query,
      variables,
    })
    .then(function (response) {
      return response.data;
    });
};
